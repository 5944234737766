










































import { reactive, toRefs, defineComponent } from '@vue/composition-api';
import { useAuthActions } from '@/store';
import ATextInput from '@/components/atoms/ATextInput.vue';
import AButton from '@/components/atoms/AButton.vue';

interface IState {
  password: string;
  msg: string;
  type: string;
  loading: boolean;
  isLoginPopupVisible: boolean;
}

export default defineComponent({
  components: {
    ATextInput,
    AButton,
    MBasicUserInfo: () => import('@/components/molecules/m-basic-user-info-dialog.vue')
  },
  props: {
    token: {
      type: String,
      default: ''
    },
    tokenId: {
      type: String,
      default: ''
    }
  },

  setup(props, vm) {
    const state: IState = reactive({
      password: '',
      msg: '',
      type: 'success',
      loading: false,
      isLoginPopupVisible: false
    });
    const { changePassword } = useAuthActions(['changePassword']);
    const submit = async () => {
      state.loading = true;
      try {
        await changePassword({
          email: vm.root.$route.query.username,
          password: state.password
        });
        state.msg = 'Your password has been changed';
        state.type = 'success';
        // vm.root.$router.push({ name: 'login' });
        state.isLoginPopupVisible = true;
      } catch (err) {
        state.msg = err;
        state.type = 'error';
      }
      state.loading = false;
    };

    return { ...toRefs(state), submit };
  }
});
