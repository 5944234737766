



























































import { computed, defineComponent, ref, Ref, watch } from '@vue/composition-api';
import { useUserActions, useUserState } from '@/store';
import Vue from 'vue';
import Meta from 'vue-meta';

const { user }: any = useUserState(['user']);
const { getDataWithShareableUrl } = useUserActions(['getDataWithShareableUrl']);

Vue.use(Meta, {
  keyName: 'metaInfo'
});

export default defineComponent({
  name: 'Details',
  components: {
    Navbar: () => import('@/components/Navbar.vue'),
    MBasicUserInfo: () => import('@/components/molecules/m-basic-user-info-dialog.vue')
  },
  props: {
    program_name: {
      type: String,
      required: false,
      default: ''
    },
    schemaType: {
      type: String,
      required: false,
      default: ''
    },
    isTemplate: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data: () => ({
    programDoc: { data: {} },
    urlValue: '',
    sharable_url: '',
    currentOrigin: window.location.origin,
    user,
    isLoginPopupVisible: false
  }),
  created() {
    this.urlValue = this.getDomainByEnvironmentName();
    this.sharable_url = `${this.urlValue}/${this._props.schemaType}/${this._props.program_name}`;
    this.getProgramDataBySharableUrl();
  },
  methods: {
    getDomainByEnvironmentName() {
      let domain;
      if (process.env.VUE_APP_NODE_ENV === 'development') {
        domain = 'develop.pilotcity.com';
      } else if (process.env.VUE_APP_NODE_ENV === 'stage') {
        domain = 'stage.pilotcity.com';
      } else {
        domain = 'pilotcity.com';
      }
      return domain;
    },

    async getProgramDataBySharableUrl() {
      const response = await getDataWithShareableUrl({
        sharable_url: this.sharable_url,
        document_type: this._props?.isTemplate ? 'ProgramSchema' : 'Program'
      });
      if (response) {
        this.programDoc.data = response;
        if (
          this.programDoc?.data?.visibility === 'private' &&
          !localStorage.getItem('apollo-token')
        ) {
          // this.$router.push({ name: 'login' });
          this.isLoginPopupVisible = true;
        }
      }
    },

    redirectToRoute() {
      if (this._props?.isTemplate) {
        this.$router.push({
          name: 'programSetup',
          params: {
            program_name: this._props.program_name,
            type: 'template'
          }
        });
      } else if (
        !this._props?.isTemplate &&
        user?.value &&
        this.programDoc?.data?.organizers?.find(
          org => org?.userId?.toString() === user?.value?._id?.toString()
        )
      ) {
        this.$router.push({
          name: 'programSetup',
          params: {
            program_name: this._props.program_name,
            type: 'program'
          }
        });
      } else {
        this.$router.push({
          name: 'program',
          params: {
            program_name: this._props.program_name,
            type: 'program'
          }
        });
      }
    }
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: this._props?.isTemplate
            ? this?.programDoc?.data?.templateDesc
            : this?.programDoc?.data?.programDesc
        },
        { name: 'keywords', content: this?.programDoc?.data?.metaData?.keywords },
        { name: 'tags', content: this?.programDoc?.data?.metaData?.tags },
        { name: 'ogTitle', content: this?.programDoc?.data?.metaData?.ogTitle },
        { name: 'ogUrl', content: this?.programDoc?.data?.metaData?.ogUrl },
        { name: 'ogDesc', content: this?.programDoc?.data?.metaData?.ogDesc },
        { name: 'twitterTitle', content: this?.programDoc?.data?.metaData?.twitterTitle },
        { name: 'twitterUrl', content: this?.programDoc?.data?.metaData?.twitterUrl },
        { name: 'twitterDesc', content: this?.programDoc?.data?.metaData?.twitterDesc },
        { name: 'linkedinTitle', content: this?.programDoc?.data?.metaData?.linkedinTitle },
        { name: 'linkedinUrl', content: this?.programDoc?.data?.metaData?.linkedinUrl },
        { name: 'linkedinDesc', content: this?.programDoc?.data?.metaData?.linkedinDesc }
      ]
    };
  }
});
