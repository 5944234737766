






























































































































































































import { onLogout } from '@/vue-apollo';

import { useAuthActions, useUserActions, useUserGetters } from '@/store';
import Profile from '@/components/Profile.vue';
import MSelectTemplate from '@/components/molecules/settings/m-select-template.vue';
import { computed, ref } from '@vue/composition-api';

export default {
  components: {
    Profile,
    MSelectTemplate
  },

  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },

  setup(_props, ctx) {
    const { logout: logoutProcess } = useAuthActions(['logout']);
    const { getUser } = useUserGetters(['getUser']);
    const user = computed({
      get: () => getUser.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const permissions = computed({
      get: () => localStorage?.getItem('permissions')?.split(',') || [],
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const logout = async () => {
      await logoutProcess();
      await onLogout();
      // ctx.root.$router.push({ name: 'login' });
      window.location.href = '/';
    };
    const selectTemplate = ref(false);
    const openTemplateDialog = () => {
      selectTemplate.value = true;
    };
    const userTypeValues = ref(['Employer', 'Teacher', 'Parent', 'School']);
    return {
      logout,
      user,
      selectTemplate,
      openTemplateDialog,
      permissions,
      userTypeValues
    };
  },

  data() {
    return {
      menuList: false
    };
  },

  methods: {
    openMenu() {
      this.menuList = !this.menuList;
    }
  }
};
