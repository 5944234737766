










import { computed, defineComponent, ref } from '@vue/composition-api';
import Navbar from '@/components/Navbar.vue';
import ASnackbar from '@/components/atoms/a-snackbar.vue';
import { useToolGetters, useUserGetters } from '@/store';
import '@/styles/main.scss';

export default defineComponent({
  name: 'App',
  components: {
    Navbar,
    ASnackbar,
    MPopupBalloon: () => import('@/components/molecules/m-popup-balloon.vue')
  },
  setup(props, context: any) {
    const { getUser, isDialogOpen } = useUserGetters(['getUser', 'getObjectId', 'isDialogOpen']);
    document.title = 'PilotCity  |  Build projects to win internships';
    if (process.env.VUE_APP_NODE_ENV === 'production' && window.$chatwoot && getUser) {
      // FIXME: Enable HMAC validation
      const computedValue = getUser.value;
      if (computedValue) {
        window.$chatwoot?.setUser(computedValue._id, {
          email: computedValue.email,
          name: computedValue.firstName + computedValue.lastName,
          phone_number: computedValue.phoneNumber
        });
      }
    }
    const { getLinearLoading: loading } = useToolGetters(['getLinearLoading']);
    const password = ref('');
    const show1 = ref(false);
    const isResetting = ref(false);

    // console.log('isLoginPopupVisible.value : ', isLoginPopupVisible.value);
    // // Event listener for popup
    // context.root.$on('openPopup', () => {
    //   isLoginPopupVisible.value = true; // Set it to true when the event is emitted
    //   console.log('Popup opened: ', isLoginPopupVisible.value);
    // });

    const layout = computed(() => {
      return `${context.root.$route.meta.layout || 'default'}-layout`;
    });
    return {
      layout,
      loading,
      isDialogOpen,
      password,
      show1,
      isResetting
    };
  }
  // provide: {

  // },
});
