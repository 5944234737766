import Vue from 'vue';
import VueRouter from 'vue-router';
import { useUserGetters, useUserActions } from '@/store';
import routes from './routes';

const { fetchUser, forcedLogout, fetchUserById } = useUserActions([
  'fetchUser',
  'forcedLogout',
  'fetchUserById'
]);

Vue.use(VueRouter);

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

const { getUser, getObjectId } = useUserGetters(['getUser', 'getObjectId']);

router.beforeEach(async (to, from, next) => {
  if (process.env.VUE_APP_MAINTENANCE === 'true' && to.name !== 'maintenance') {
    next({ name: 'maintenance' });
  } else {
    if (to.name === 'login' || to.name === 'signup') {
      // If the user is logged in, redirect to a different page (e.g., 'portfolio')
      if (getUser.value || localStorage.getItem('apollo-token')) {
        next({ name: 'portfolio' });
      } else {
        next({
          name: 'landing',
          params: {
            openLoginPopup: 'true'
          }
        });
      }
      return;
    }
    if (!getUser.value && localStorage.getItem('apollo-token')) {
      await fetchUser(localStorage.getItem('apollo-token'));
    }
    if (getUser.value?.forceChangedPwd && localStorage.getItem('apollo-token')) {
      await forcedLogout();
    }
    if (to.matched.some(record => record.meta.requiresAuth || record.meta.requiresUser)) {
      if (!getUser.value) {
        next({
          name: 'signup',
          params: {
            page: to.name!
          }
        });
      }
      next();

      if (getUser.value) {
        if (to.meta.requiresUser) {
          try {
            const user = await fetchUserById({ user_id: getObjectId.value });
            const userData = user.data;
            if (userData && userData.userTypes) {
              next();
            } else {
              next({ name: 'setup' });
            }
          } catch (error) {
            next({ name: 'setup' });
          }
        } else next();
      } else next({ name: 'login', params: { page: to.path } });
    } else next();
  }
});

export default router;
