







import { ref, onMounted } from '@vue/composition-api';
import { useToolActions } from '@/store';

const { setLinearLoader } = useToolActions(['setLinearLoader']);

export default {
  components: {
    MBasicUserInfo: () => import('@/components/molecules/m-basic-user-info-dialog.vue')
  },
  setup(_props, ctx) {
    const isLoginPopupVisible = ref(false);
    onMounted(() => {
      setLinearLoader({
        func: () =>
          new Promise<void>(resolve => {
            setTimeout(() => {
              // ctx.root.$router.push({ name: 'login' });
              isLoginPopupVisible.value = true;
              resolve();
            }, 2000);
          })
      });
    });
    return {
      isLoginPopupVisible
    };
  }
};
