


















































































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Story',
  setup() {
    const teamMembers = [
      {
        name: 'Jerold Inocencio',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/JeroldInocencio.png',
        description: ''
      },
      {
        name: 'Parul Veda',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/ParulVeda.png',
        description: ''
      },
      {
        name: 'Noah MacLean',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/NoahMacLean.png',
        description: ''
      },
      {
        name: 'Riddhi Bhandari',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/RiddhiBhandari.png',
        description: ''
      },
      {
        name: 'Timothy Deloney',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/TimDeloney.png',
        description: ''
      },
      {
        name: 'Prajit Saravanan',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/PrajitSaravanan.png',
        description: ''
      },
      {
        name: 'Jason Yang',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/JasonYang.png',
        description: ''
      },
      {
        name: 'Nick Falk',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/NickFalk.png',
        description: ''
      },
      {
        name: 'Samantha Wilcox',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/SamanthaWilcox.png',
        description: ''
      },
      {
        name: 'Ayan Shah',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AyanShah.png',
        description: ''
      },
      {
        name: 'Devarsh Jayakrishnan',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/DevarshJayakrishnan.png',
        description: ''
      },
      {
        name: 'Erik Pedersen',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/ErikPedersen.png',
        description: ''
      }
      //   {
      //     name: 'Vidhya Vishwanath',
      //     image: '',
      //     description: ''
      //   },
      //   {
      //     name: 'Arshia Sharma',
      //     image: '',
      //     description: ''
      //   },
      //   {
      //     name: 'Leon Beckman-Forman',
      //     image: '',
      //     description: ''
      //   },
      //   {
      //     name: '',
      //     image: '',
      //     description: ''
      //   },
      //   {
      //     name: '',
      //     image: '',
      //     description: ''
      //   },
      //   {
      //     name: '',
      //     image: '',
      //     description: ''
      //   },
      //   {
      //     name: '',
      //     image: '',
      //     description: ''
      //   },
      //   {
      //     name: '',
      //     image: '',
      //     description: ''
      //   },
    ];

    return {
      teamMembers
    };
  }
});
