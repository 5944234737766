var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"set-password__wrapper",attrs:{"fill-height":"","align-center":"","justify-center":"","d-flex":""}},[_c('v-row',{staticClass:"reset-password",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"sm":"2","md":"4"}}),_c('v-col',{attrs:{"sm":"8","md":"4"}},[(_vm.tokenVerified && !_vm.pageLoading)?_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{},[_c('h1',{staticClass:"text-center mb-15 text-decoration-underline"},[_vm._v("Set Password")]),_c('validation-provider',{attrs:{"slim":"","rules":{
              min: 8,
              passwordRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z!@#$%^&*0-9]{8,}$/,
              required: true
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{class:_vm.stylings.newPassword.class ? _vm.stylings.newPassword.class : null,attrs:{"x-large":_vm.stylings.newPassword.xLarge ? _vm.stylings.newPassword.xLarge : false,"rounded":_vm.stylings.newPassword.rounded ? _vm.stylings.newPassword.rounded : false,"small":_vm.stylings.newPassword.small ? _vm.stylings.newPassword.small : false,"outlined":_vm.stylings.newPassword.outlined ? _vm.stylings.newPassword.outlined : false,"icon":_vm.stylings.newPassword.icon ? _vm.stylings.newPassword.icon : false,"full-width":_vm.stylings.newPassword.fullWidth ? _vm.stylings.newPassword.fullWidth : false,"label":_vm.stylings.newPassword.label ? _vm.stylings.newPassword.label : 'label',"hint":_vm.stylings.newPassword.hint ? _vm.stylings.newPassword.hint : '',"placeholder":_vm.stylings.newPassword.placeholder
                  ? _vm.stylings.newPassword.placeholder
                  : 'placeholder',"color":_vm.stylings.newPassword.color ? _vm.stylings.newPassword.color : 'white',"counter":_vm.stylings.newPassword.counter ? _vm.stylings.newPassword.counter : null,"type":_vm.show1 ? 'text' : 'password',"toggle":_vm.stylings.newPassword.toggle ? _vm.stylings.newPassword.toggle : false,"single-line":true,"error-messages":errors,"append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off'},on:{"click:append":_vm.togglePasswordFirst},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})]}}],null,true)}),_c('validation-provider',{attrs:{"slim":"","rules":{
              min: 8,
              passwordRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z!@#$%^&*0-9]{8,}$/,
              required: true
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{class:_vm.stylings.reEnterPassword.class ? _vm.stylings.reEnterPassword.class : null,attrs:{"x-large":_vm.stylings.reEnterPassword.xLarge ? _vm.stylings.reEnterPassword.xLarge : false,"rounded":_vm.stylings.reEnterPassword.rounded ? _vm.stylings.reEnterPassword.rounded : false,"small":_vm.stylings.reEnterPassword.small ? _vm.stylings.reEnterPassword.small : false,"outlined":_vm.stylings.reEnterPassword.outlined ? _vm.stylings.reEnterPassword.outlined : false,"icon":_vm.stylings.reEnterPassword.icon ? _vm.stylings.reEnterPassword.icon : false,"full-width":_vm.stylings.reEnterPassword.fullWidth ? _vm.stylings.reEnterPassword.fullWidth : false,"label":_vm.stylings.reEnterPassword.label ? _vm.stylings.reEnterPassword.label : 'label',"hint":_vm.stylings.reEnterPassword.hint ? _vm.stylings.reEnterPassword.hint : '',"placeholder":_vm.stylings.reEnterPassword.placeholder
                  ? _vm.stylings.reEnterPassword.placeholder
                  : 'placeholder',"color":_vm.stylings.reEnterPassword.color ? _vm.stylings.reEnterPassword.color : 'black',"counter":_vm.stylings.reEnterPassword.counter ? _vm.stylings.reEnterPassword.counter : null,"type":_vm.show2 ? 'text' : 'password',"toggle":_vm.stylings.reEnterPassword.toggle ? _vm.stylings.reEnterPassword.toggle : false,"single-line":true,"error-messages":errors.length
                  ? errors
                  : !_vm.isPassSame && _vm.reEnterPassword
                  ? 'Password and Confirm password must be same'
                  : null,"append-icon":_vm.show2 ? 'mdi-eye' : 'mdi-eye-off'},on:{"click:append":_vm.togglePasswordSecond},model:{value:(_vm.reEnterPassword),callback:function ($$v) {_vm.reEnterPassword=$$v},expression:"reEnterPassword"}})]}}],null,true)})],1),_c('AButton',{attrs:{"width":"100%","color":"blue","loading":_vm.loading,"styling":{
            wrapperClass: 'text-center mt-10 mb-10 font-weight-bold',
            color: 'blue',
            rounded: true,
            xLarge: true
          },"disabled":invalid || !_vm.isPassSame},on:{"click":_vm.forcedPass}},[_vm._v("Reset Password")]),(_vm.msg)?_c('v-alert',{attrs:{"type":_vm.type,"border":"left","dismissible":"","elevation":"2"}},[_vm._v(_vm._s(_vm.msg))]):_vm._e()]}}],null,false,4029865805)}):(!_vm.pageLoading)?_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var invalid = ref.invalid;
return [(_vm.isTokenInvalid)?_c('h1',{staticClass:"text-center"},[_vm._v("[Error!] Link is invalid!")]):(_vm.isTokenUsed)?_c('h1',{staticClass:"text-center"},[_vm._v("[Error!] Your link is already used.")]):_c('h1',{staticClass:"text-center"},[_vm._v(" [Error!] Your link has expired. Please click below to resend the email. ")]),(!_vm.isTokenUsed && !_vm.isTokenInvalid)?_c('AButton',{attrs:{"width":"100%","color":"blue","loading":_vm.loading,"styling":{
            wrapperClass: 'text-center mt-10 mb-10 font-weight-bold text-red',
            color: 'blue',
            rounded: true,
            xLarge: true
          },"disabled":invalid || _vm.isEmailSent},on:{"click":_vm.resendEmail}},[_vm._v("Resend Email")]):_vm._e(),(_vm.msg)?_c('v-alert',{attrs:{"type":_vm.type,"border":"left","dismissible":"","elevation":"2"}},[_vm._v(_vm._s(_vm.msg))]):_vm._e()]}}])}):_c('div')],1),_c('v-col',{attrs:{"sm":"2","md":"4"}})],1),_c('MBasicUserInfo',{attrs:{"isfromlandingpage":true},model:{value:(_vm.isLoginPopupVisible),callback:function ($$v) {_vm.isLoginPopupVisible=$$v},expression:"isLoginPopupVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }